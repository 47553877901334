import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Subsc from '../../assets/images/subsc.png'

import 'animate.css';
import { Slide, Flip } from "react-awesome-reveal";
import {useTranslation} from "react-i18next";
const CenteredImage = () => {
    const { t } = useTranslation();
  return (
    <Container fluid className="head" style={{backgroundColor:'rgba(180, 140, 252, .1)'}}>
      <Row className="align-items-center" style={{height:300, minHeight:300}}>
        <Col xs={12} md={6} className="order-md-2 order-1">
          <h2 className="text-center text-md-left" style={{color:'#430C05',letterSpacing:-1, fontWeight:'bold'}}>
            <span className="d-block d-md-inline" style={{fontSize:'2rem', fontFamily:'Roobert, sans-serif'}}>{t('second.Text')}</span>
          </h2>
        </Col>
        <Col xs={12} md={6} className="order-md-1 order-2 text-center">
         <img 
              className="d-block w-75 mx-auto mt-4 mt-md-0"
              src={Subsc}
              alt=""
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CenteredImage;