import React from "react";
import './ProductCard.css'
const ProductCard = ({ title, subtitle, children, image1, image2, image3, image4 }) => {
  return (
      <div className="p-0 card w-100">
          <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-row align-items-center">
                      <h5 className="card-title">{title}</h5>
                      <h6 className="card-subtitle ms-3 mt-2">{subtitle}</h6>
                  </div>
              </div>
              {children}
              <div className="sous d-flex flex-column justify-content-center align-items-center">
                  <div className="mb-2">
                      <img
                          src={image1}
                          className="w-75"
                          alt=""
                      />
                  </div>
                  <div>
                      <img
                          className="w-75"
                          src={image2}
                          alt=""
                      />
                      <img
                          className="w-75 pt-0 position-relative"
                          src={image3}
                          style={{left: 45}}
                          alt=""
                      />
                      <img
                          className="w-75 pt-0 position-relative"
                          src={image4}
                          style={{left: 35}}
                          alt=""
                      />
                  </div>
              </div>
          </div>
      </div>

  );
};

export default ProductCard;