import { Route, Routes } from "react-router-dom";
import HomePage from '../pages/homePage/homePage';
import LoginPage from '../pages/login/login';
import SignUpPage from '../pages/signup/signup';
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/TermCondition/TermsAndConditions";

const Routing = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        </Routes>
    );
};

export default Routing;
