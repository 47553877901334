import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const TermsAndConditions = () => {
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <Card className="shadow-lg">
                        <Card.Header as="h3" className="text-center py-3 text-white" style={{backgroundColor:'#d3ae39'}}>
                            Conditions Générales D'Utilisation Pour Clients Utilisateurs Netikash
                        </Card.Header>
                        <Card.Body>
                            <Card.Text as="div">
                                <h4 className="my-3">1. Stipulations générales</h4>
                                <p>
                                    Nous vous demandons de lire attentivement les présentes conditions d'utilisation,
                                    car elles représentent le contrat qui vous lie à NETIKASH SAS, dans le cadre de
                                    l’utilisation de son système de paiement en ligne Netikash ainsi que des cartes de
                                    paiement Netikash.
                                </p>
                                <h6>1.2. Gestion du compte</h6>
                                <p>
                                    Votre compte Netikash est géré par la société NETIKASH SAS (« Société » ou « Nous »),
                                    dont le siège social est situé au 01, Av. Kasangulu C/Lubumbashi Haut-Katanga / Lubumbashi – RDC.
                                </p>
                                <p>
                                    À la création de votre compte Netikash sur <a href="http://www.netikash.com">www.netikash.com</a>,
                                    vous déclarez avoir lu et accepté l'application des présentes conditions d'utilisation.
                                </p>
                                <h6>1.3. Création du portefeuille électronique</h6>
                                <p>
                                    En créant votre compte utilisateur Netikash, vous créez un portefeuille électronique.
                                    À sa création, votre portefeuille électronique a un solde nul et est configuré pour
                                    effectuer et accepter les transactions en Franc congolais (CDF) et Dollar Américain (USD).
                                </p>
                                <p>
                                    Toute modification des présentes conditions sera indiquée sur le site web
                                    (<a href="http://www.netikash.com">www.netikash.com</a>) une semaine avant leur entrée en vigueur.
                                    Vous êtes donc invités à consulter régulièrement le Site Web pour vérifier de telles modifications.
                                </p>
                                <h6>1.4. Accord tacite</h6>
                                <p>
                                    Il est convenu que vous avez donné votre accord en vue de la modification proposée,
                                    si vous ne déclarez pas que vous y renoncez par écrit avant le moment proposé pour
                                    l'entrée en vigueur de cette modification.
                                </p>
                                <p>
                                    Nous attirons votre attention sur les conséquences de votre silence dans l'offre
                                    relative à la modification du contrat, de même que sur la possibilité d'une résiliation
                                    immédiate et sans frais. Voir la section 6 (Annulation) pour plus de détails.
                                </p>
                                <h6>1.5. Téléchargement des conditions</h6>
                                <p>
                                    Vous pouvez télécharger ces conditions d'utilisation à tout moment sur le Site
                                    Web (<a href="http://www.netikash.com">www.netikash.com</a>) ou nous en demander une copie papier.
                                </p>

                                <h4 className="my-3">2. Frais</h4>
                                <p>
                                    Les frais suivants sont appliqués pour l'utilisation de Netikash :
                                </p>
                                <ListGroup>
                                    <ListGroup.Item>
                                        <strong>2.1.</strong> Les frais de paiement marchand sont nuls pour tout paiement
                                        effectué à partir de votre compte Netikash. Les frais de transfert de monnaie
                                        entre utilisateurs Netikash s’élèvent à 1% (un pourcent), à charge de la personne
                                        qui initie le transfert d’argent.
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>2.2.</strong> Les frais de retrait s’élèvent à 1.5% (un virgule cinq pourcent)
                                        du montant à retirer. 55% de ces frais sont remis à l’agent Netikash chez qui le retrait
                                        est effectué. Ce montant constitue son bonus sur retrait.
                                    </ListGroup.Item>
                                </ListGroup>

                                <h4 className="my-3">3. Différents avantages</h4>
                                <p>
                                    Vous pouvez payer avec votre compte Netikash chez les commerçants et les fournisseurs
                                    de produits ou services en ligne acceptant Netikash comme moyen de paiement. L'utilisation
                                    de Netikash nécessite un appareil connecté à Internet, y compris l'activation de JavaScript
                                    dans le navigateur.
                                </p>
                                <p>
                                    Pour effectuer un paiement avec votre compte Netikash, sur le formulaire de paiement sur
                                    le site de votre marchand, il vous sera demandé d’entrer votre numéro de téléphone, le même
                                    que vous avez utilisé lors de la création de votre compte Netikash. Après validation, un OTP
                                    (One-Time-Password) vous sera envoyé par SMS. Vous devrez saisir cet OTP sur le site web
                                    du marchand pour confirmer le paiement.
                                </p>
                                <p>
                                    En validant le paiement sur le site de votre marchand, vous autorisez irrévocablement
                                    le partenaire marchand à débiter immédiatement votre compte du montant correspondant au
                                    prix de la commande du produit ou service. Vous autorisez également Netikash à transférer
                                    ce montant au compte Netikash du partenaire marchand.
                                </p>

                                <h4 className="my-3">4. Annulation</h4>
                                <p>
                                    Nous nous réservons le droit de bloquer immédiatement votre compte Netikash si nous
                                    suspectons une fraude ou un usage impropre, ou pour toute autre raison de sécurité.
                                    Nous vous fournirons toutes les explications nécessaires dans la mesure du possible.
                                </p>

                                <h4 className="my-3">5. Devise</h4>
                                <p>
                                    Netikash prend en charge les paiements en Francs congolais (CDF) et en Dollars américains (USD).
                                    Tous les paiements doivent se faire à partir du portefeuille électronique Netikash correspondant
                                    à la devise utilisée par le partenaire marchand.
                                </p>
                                <p>
                                    Si le paiement se fait dans une autre devise, la conversion se fera au taux de change
                                    en vigueur à la date de la transaction, avec des frais de conversion de 0,5% (zéro virgule cinq pourcents).
                                </p>

                                <h4 className="my-3">6. Réclamations</h4>
                                <p>
                                    Si vous êtes mécontent de Netikash ou de la manière dont notre service est géré, faites-le
                                    nous savoir par courriel à l’adresse <a href="mailto:info@netikash.com">info@netikash.com</a>,
                                    afin que nous puissions examiner les faits pour vous. Toutes vos réclamations seront traitées
                                    rapidement et équitablement.
                                </p>

                                <h4 className="my-3">7. Litige avec les marchands</h4>
                                <p>
                                    Vous devez régler les litiges concernant les achats que vous avez effectués à l’aide de Netikash
                                    avec le marchand en cause. Nous ne sommes pas responsables de la qualité, de la sécurité, de la
                                    légalité ou de tout autre aspect des produits ou services payés à l’aide de Netikash.
                                </p>
                                <p>
                                    Dès l’instant où vous avez utilisé votre compte Netikash pour effectuer un paiement, nous ne pouvons
                                    plus arrêter la transaction et le paiement afférent.
                                </p>

                                <h4 className="my-3">8. Responsabilité</h4>
                                <p>
                                    En cas de paiement incorrectement exécuté en raison d’une erreur de notre part, nous nous engageons,
                                    aussitôt que possible, à vous restituer la totalité de la somme dépensée lors du paiement. Cependant,
                                    nous ne sommes pas responsables des dommages indirects tels que la perte de profit ou la perte de réputation.
                                </p>

                                <h4 className="my-3">9. Protection des données</h4>
                                <p>
                                    Vous nous autorisez à accéder, traiter et conserver toutes les informations communiquées
                                    aux fins de fournir les services de paiement. Aucune donnée personnelle n’est collectée lorsque
                                    vous achetez ou utilisez Netikash, sauf si vous les mettez à notre disposition sur notre Site Web.
                                </p>

                                <h4 className="my-3">10. Droits applicables et tribunaux compétents</h4>
                                <p>
                                    Les présentes conditions d'utilisation sont régies par le droit congolais. En cas d'absence de résolution
                                    amiable, les litiges nés entre les parties seront jugés conformément à la loi et soumis à la juridiction
                                    des tribunaux compétents du siège social.
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TermsAndConditions;
