import React, { useState } from 'react';
import './LanguageSwitcher.css';

const LanguageSwitcher = ({ changeLanguage }) => {
    const [isFrench, setIsFrench] = useState(true);

    const toggleLanguage = () => {
        setIsFrench(!isFrench);
        changeLanguage(isFrench ? 'en' : 'fr');
    };

    return (
        <div className="language-switcher" onClick={toggleLanguage}>
            <div className={`switch ${isFrench ? 'french' : 'english'}`}>
                <div className="toggle"></div>
                <span className="label">{isFrench ? 'EN' : 'FR'}</span>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
