import React, {useEffect} from 'react';
import './homePage.css'
import {useTranslation} from "react-i18next";
import i18n from "../../config/i18n";
import CarbonCarousel from '../../components/CarbonCarousel/CarbonCarousel';
import Head from '../../components/Head/Head';
import HeadCard from '../../components/Cards/HeadCard';
import SecondCard from '../../components/Cards/SecondCard';
import SiteFooter from '../../components/footer/Sitefooter';
import CenterCard from '../../components/center/CenterCard';
import ProductCard from '../../components/ProductCards/ProductCard';
import Screen1 from "../../assets/images/screen1.png"
import Screen2 from "../../assets/images/screen2.png"
import Screen3 from "../../assets/images/screen3.png"
import Store from "../../assets/images/store.png"
import Play from "../../assets/images/play.png"
import Art3 from "../../assets/images/Art3.png"
import Art4 from "../../assets/images/My Pos.jpg"
import Art2 from "../../assets/images/Art2.png"
import Art1 from "../../assets/images/Art1.png"
import Product from '../../components/Products/product';
import 'animate.css';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
const HomePage = () =>{
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        if (location.hash === "#pos-section") {
            const element = document.getElementById("pos-section");
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);
  return (
    <div className="container-fluid p-0 m-0">
      <div className="header">
        <Head />
      </div>
      <div className="mt-5">
        <CenterCard />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="mb-4 mt-5">
            <HeadCard />
            <div className='d-flex flex-row justify-content-center mt-1 mb-2'>
            <Button className="m-1 button-87">
              <a href='https://accounts.netikash.com' target="_blank" style={{fontSize:16, lineHeight:2, textDecoration:'none', color:'black', fontFamily:'Roobert, sans-serif'}}>{t('second.bouton')}</a>
            </Button>
            </div>
          </div>
            <div className="">
                <div className='mb-5 mt-5'>
                    <SecondCard
                        image={Art1}
                            titre={t('homepage.netikash_pay_title')}
                        description={t('homepage.netikash_pay_description')}
                        action={t('homepage.action_pay')} href='https://play.google.com/store/apps/details?id=com.netikash.netikashwalletapp'
                    />
                </div>
                <div className='mb-5 mt-5'>
                    <Product
                        image={Art3}
                        titre={t('homepage.netikash_payme_title')}
                        description={t('homepage.netikash_payme_description')}
                        action={t('homepage.action_payme')} href='https://play.google.com/store/apps/details?id=com.netikash.netikashpaymeapp'
                    />
                </div>
                <div className='mb-5 mt-5'>
                    <SecondCard
                        image={Art2}
                        titre={t('homepage.netikash_subscribe_title')}
                        description={t('homepage.netikash_subscribe_description')}
                        action={t('homepage.action_sub')} href='https://subscribe.netikash.com'
                    />
                </div>
                <div className='mb-5 mt-5' id="pos-section">
                    <Product
                        image={Art4}
                        titre={t('homepage.netikash_pos_title')}
                        description={t('homepage.netikash_pos_description')}
                        action={t('homepage.action_pos')} href='https://play.google.com/store/apps/details?id=com.netikash.netikashposapp'
                    />
                </div>
            </div>
        </div>
      </div>
        <Container className="p-0 duval mb-5">
            <h3 className="w-100 position-relative mb-5"
                style={{color: '#430C05', fontWeight: 'bold', fontSize: 30, textAlign: 'start', marginLeft: 10, fontFamily:'Roobert, sans-serif'}}>
                {t('bannier.text')}
            </h3>
            <Row className="position-relative w-100" style={{ bottom: 0, display: 'flex' }}>
                <Col xs={12} md={6} lg={4} className="d-flex align-items-stretch">
                    <ProductCard title="01" subtitle="Netikash Pay" image4={Screen1} style={{ flexGrow: 1 }}>
                        <p style={{ color: "#333333" }}>
                            {t('corner.titre1')}
                        </p>
                    </ProductCard>
                </Col>
                <Col xs={12} md={6} lg={4} className="d-flex align-items-stretch">
                    <ProductCard title="02" subtitle="Netikash POS" image3={Screen2} style={{ flexGrow: 1 }}>
                        <p>
                            {t('corner.titre2')}
                        </p>
                    </ProductCard>
                </Col>
                <Col xs={12} md={6} lg={4} className="d-flex align-items-stretch">
                    <ProductCard title="03" subtitle="Netikash Payme" image4={Screen3} style={{ flexGrow: 1 }}>
                        <p>
                            {t('corner.titre3')}
                        </p>
                    </ProductCard>
                </Col>
            </Row>
        </Container>
    <div className='container p-0'>
      <CarbonCarousel />
    </div>
        <SiteFooter />
    </div>
  )
};

export default HomePage;
