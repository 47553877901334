import React from "react";
import 'animate.css';
import './product.css'
const Product = ({titre, description, action, image, href}) => {
  return (
    <div className="container p-0">
      <div className="row align-items-center">
      <div className="col-md-5 col-lg-6 order-md-1 order-1">
          <img
            srcset={`${image} 1x, ${image.replace('-800', '-1600')} 2x`}
            sizes="(max-width: 600px) 480px, 800px"
            src={image}
            className="d-block w-100"
            style={{height: "auto", borderRadius: 10 }}
            alt=""
          />
        </div>
        <div className="col-md-7 col-lg-6 order-md-2 order-2">
          <h2 className="titre">{titre}</h2>
          <span className="description">
            {description}
          </span>
          <div className="button-87 mt-2 p-2 m-2  w-75 position-relative" style={{cursor:'pointer'}}>
          <a href={href} target="_blank" style={{textDecoration: 'none', color: 'white'}}>
              <h5 style={{bottom:7, position:'relative', fontFamily:'Roobert, sans-serif'}}>{action}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;