import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './PrivacyPolicy.css';
const PrivacyPolicy = () => {

    return(
        <Container>
            <Row className="justify-content-center">
                <Col md={10}>
                    <Card className="policy-card">
                        <Card.Header as="h3" className="text-center" style={{color:'white', backgroundColor:'#d3ae39'}}>Politique de Confidentialité</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <h5>1. INTRODUCTION</h5>
                                <p>
                                    La confidentialité de tout visiteur de notre site web www.netikash.com est très importante pour nous et
                                    nous nous sommes engagés à la sauvegarder à tout prix, dans la mesure du possible. Cette politique
                                    explique ce que nous faisons avec toutes les informations personnelles que nous récoltons auprès de tous
                                    nos utilisateurs
                                </p>
                                <p>
                                    En visitant notre site web www.netikash.com, vous consentez à l’utilisation des cookies sur l’appareil
                                    ou les appareils utilisés pour vous connecter sur le site web et conformément aux termes de cette
                                    politique, nous pouvons utiliser ces cookies à chaque fois que vous allez visiter notre site
                                </p>
                                <h5>2 : DONNEES D’IDENTIFICATION PERSONNELLES </h5>
                                <p>
                                    Lors de votre visite et de votre connexion sur le site web www.netikash.com, les informations d’identification personnelles suivantes peuvent être collectées, stockées et utilisées :
                                </p>
                                <ul>
                                    <li>
                                        Les informations sur votre ordinateur, y compris votre adresse IP, votre
                                        position géographique, le type
                                        et la version du navigateur ainsi que des informations sur votre système
                                        d'exploitation ;
                                    </li>
                                    <li>
                                        Les informations sur vos visites et votre utilisation du site, y compris les
                                        pages visitées, la durée de
                                        chaque visite et tous les chemins et liens de navigation empruntés pendant votre
                                        visite sur le site web
                                        www.netikash.com. Les informations que vous entrez lorsque vous vous inscrivez
                                        sur notre site et que
                                        vous créez un profil, telles que votre adresse email, votre nom complet, votre
                                        genre, votre photo de
                                        profil, votre numéro de téléphone et votre date de naissance seront aussi
                                        collectées.
                                    </li>
                                    <li>
                                        Les informations générées lors de l'utilisation de notre site web, y compris
                                        quand, à quelle fréquence
                                        et dans quelles circonstances vous l'utilisez.
                                    </li>
                                    <li>
                                        Les informations relatives à tout ce que vous achetez, aux services que auxquels
                                        vous souscrivez ou aux
                                        transactions que vous effectuez sur notre site Web, qui pourraient comprendre
                                        votre nom, adresse, numéro
                                        de téléphone, adresse e-mail et certains détails sur le mode de paiement
                                        utilisé.
                                    </li>
                                    <li>
                                        Informations sur tout ce que vous publiez sur notre site Web avec l'intention de
                                        voir ces informations
                                        être repartagées et publiées sur internet.
                                    </li>
                                    <h5>3 : UTILISATION DES INFORMATIONS D’IDENTIFICATION PERSONNELLES </h5> <p> Les
                                    informations d’identification personnelles qui nous sont envoyées via notre site web
                                    www.netikash.com seront utilisées à des fins spécifiées dans la présente politique
                                    ou sur les pages correspondantes de notre site web <a
                                        href="https://www.netikash.com">www.netikash.com</a>. </p> <p> Nous
                                    pouvons utiliser vos informations d’identification personnelles pour les fins
                                    suivantes : </p>
                                    <ul>
                                        <li> Administre notre site web et nos opérations ;</li>
                                        <li> Personnaliser notre site pour vous offrir une meilleure expérience ;</li>
                                        <li> Vous autoriser à utiliser les services disponibles sur notre site web ;
                                        </li>
                                        <li> Envoyer les produits que vous aurez achetés via notre site web ;</li>
                                        <li> Fournir les services que vous aurez achetés via notre site web ;</li>
                                        <li> Envoyer des relevés, des factures et des rappels de paiement, ainsi que
                                            recueillir des paiements de votre part ;
                                        </li>
                                        <li> Envoyer des communications commerciales, marketing et des publicités ;</li>
                                        <li> Envoyer des notifications par courrier électronique que vous aurez
                                            spécifiquement demandées ;
                                        </li>
                                        <li> Envoyer notre newsletter par e-mail si vous vous y êtes inscrit (vous
                                            pouvez vous désinscrire à tout moment) ;
                                        </li>
                                        <li> Envoyer des communications marketing relatives à notre activité ou à celle
                                            des entreprises tierces qui, à notre avis, pourraient vous intéresser ;
                                        </li>
                                        <li> Fournir à des tiers des informations statistiques sur nos utilisateurs ;
                                        </li>
                                        <li> Traiter vos demandes de renseignements et vos plaintes concernant
                                            l’utilisation de vos données sur notre site web ;
                                        </li>
                                        <li> Vérifier le respect des conditions générales régissant l’utilisation de
                                            notre site Web.
                                        </li>
                                    </ul>
                                    <p> 15. Tout autre possible utilisation, telle que prévue par la loi en
                                        vigueur. </p> <p> Si vous soumettez des informations d’identification
                                    personnelles avec le but de les publier sur notre site web www.netikash.com, nous
                                    les publierons. Nous pouvons aussi utiliser ces informations conformément à notre
                                    politique générale d’utilisation, que vous pouvez retrouver <a
                                        href="https://www.netikash.com">
                                    ici
                                </a>
                                .
                            </p> <p> Sans votre consentement explicite, nous ne fournirons pas vos informations
                                    personnelles à tout tiers pour besoin de marketing, de publicité, ou tout autre
                                    besoin que ce soit. </p> <h5>4 : DIVULGATION DES INFORMATIONS D’IDENTIFICATION
                                    PERSONNELLES</h5> <p> Nous pouvons divulguer vos informations personnelles à l’un de
                                    nos employés, dirigeants, assureurs, conseillers professionnels, fournisseurs ou
                                    sous-traitants dans les mesures raisonnables nécessaires aux fins énoncées dans la
                                    présente politique. </p> <p> Nous pouvons divulguer vos informations personnelles à
                                    tout membre de la société NETIKASH SAS dans les mesures raisonnables nécessaire aux
                                    fins énoncées dans la présente politique. </p> <p> Nous pouvons divulguer vos
                                    informations personnelles : </p>
                                    <ul>
                                        <li> Dans la mesure où nous y sommes obligés par la loi en vigueur dans le pays
                                            où nous opérons ;
                                        </li>
                                        <li> Dansle cadre d’une procédure judiciaire en cours ou à venir ;</li>
                                        <li> Afin d’établir, d’exercer ou de défendre nos droits légaux ;</li>
                                        <li> A l'acheteur (ou acheteur potentiel) de toute entreprise ou actif dont nous
                                            sommes propriétaires et envisageons de vendre.
                                        </li>
                                    </ul>
                                    <p> Sauf indication contraire dans la présente politique, nous ne fournirons pas vos
                                        informations personnelles à des tiers, quels qu’ils soient. </p> <h5>5 :
                                    TRANSFERTS INTERNATIONAUX DES DONNÉES D’IDENTIFICATION PERSONNELLES</h5> <p> Les
                                    informations que nous collectons peuvent être stockées, traitées et transférées
                                    entre pays dans lesquels nous opérons afin de nous permettre de mieux les utiliser
                                    pour mieux vous servir. </p> <p> Les informations personnelles que vous publiez sur
                                    notre site web www.netikash.com ou que vous soumettez pour publication sur notre
                                    site web peuvent être disponibles, à travers Internet, partout au monde. Nous ne
                                    pouvons empêcher l'utilisation ou la mauvaise utilisation de ces informations par
                                    des tiers. </p> <p> Vous acceptez expressément les transferts d'informations
                                    personnelles décrits dans cet article 5. </p> <h5>6 : CONSERVATION DES INFORMATIONS
                                    D’IDENTIFICATION PERSONNELLES</h5> <p> Le présent article décrit nos politiques et
                                    procédures de conservation des données, conçues pour nous aider à nous conformer et
                                    à respecter nos obligations légales en matière de la conservation et de la
                                    suppression de vos informations personnelles. </p> <p> Les informations personnelles
                                    que nous traitons à quelques fins que ce soit ne doivent pas être conservées plus
                                    longtemps qu'il n'est nécessaire à cette fin ou à ces fins, dans le respect des
                                    délais prévus par la loi en vigueur dans le pays où nous opérons. </p><p> Nonobstant
                                    les autres dispositions du présent article, nous conserverons les documents (y
                                    compris les documents électroniques) contenant des données personnelles : </p>
                                    <ul>
                                        <li> Dans la mesure où la loi nous y oblige ;</li>
                                        <li> Si nous pensons que les documents conservés peuvent être pertinents pour
                                            tout travail en cours ou toute poursuite judiciaire éventuelle ;
                                        </li>
                                        <li> Afin d’établir, d’exercer ou de défendre nos droits légaux (y compris
                                            fournir des informations à des tiers à des fins de prévention de la fraude
                                            et réduction du risque de crédit).
                                        </li>
                                    </ul>
                                    <h5>7 : SECURITE DE VOS INFORMATIONS D’IDENTIFICATION PERSONNELLES</h5> <p> Nous
                                    prendrons toutes les précautions techniques et organisationnelles raisonnables pour
                                    éviter la perte, l'utilisation abusive ou l'altération de vos informations
                                    d’identification personnelles. </p> <p> Nous allons stocker toutes les informations
                                    d’identification personnelles que vous nous fournissez sur notre site web d’une
                                    manière sécurisée. </p> <p> Toutes les transactions financières électroniques
                                    conclues sur notre site web www.netikash.com seront protégées par les technologies
                                    les plus récentes et approuvées de cryptage. </p> <p> Vous reconnaissez que les
                                    transmissions d'informations sur internet sont intrinsèquement peu sûres, et nous ne
                                    pouvons totalement vous garantir la sécurité des données envoyées sur internet. </p>
                                    <p> Vous êtes responsable de conserver de façon confidentielle le mot de passe ou le
                                        code PINque vous utilisez pour accéder à notre site web www.netikash.com. Nous
                                        ne vous demanderons jamais votre mot de passe, par quelque moyen que ce soit,
                                        sauf quand vous vous connectez à notre site web. Et dans ce cas, le mot de passe
                                        devra être saisi de façon sécurisée directement sur le formulaire de connexion
                                        de notre site web www.netikash.com. </p> <h5>8 : AMENDEMENTS A CETTE
                                    POLITIQUE</h5> <p> Nous pouvons mettre à jour cette politique de temps à autre en
                                    publiant une nouvelle version sur notre site Web. </p> <p> Vous devriez consulter
                                    cette page de temps en temps pour vous assurer de bien comprendre les possibles
                                    changements à cette dernière. Nous pouvons vous informer des modifications apportées
                                    à celle-ci par courrier électronique, via le système de messagerie privé de notre
                                    site Web, ou en affichant directement les modifications directement sur le portail
                                    principal de notre site web www.netikash.com. </p> <h5>ARTICLE 9 : VOS DROITS</h5>
                                    <p> Vous pouvez nous demander de vous fournir toutes les informations
                                        d’identification personnelles que nous détenons sur vous. La fourniture de ces
                                        informations sera soumise aux conditions suivantes :</p>
                                    <ul>
                                        <li>La fourniture d’une preuve appropriée de votre identité. Pour cette
                                            dernière, nous accepterons généralement une photocopie de votre passeport ou
                                            de votre carte d’électeur certifiée par un notaire.
                                        </li>
                                        <li>Nous pouvons retenir les informations personnelles que vous
                                            demandez dans la mesure permise par loi.
                                        </li>
                                    </ul>

                                    <p> Vous pouvez nous demander à tout moment de ne pas traiter vos informations
                                        personnelles à des fins de marketing. </p> <p> En pratique, vous aurez
                                    généralement donné votre consentement d’avance, par l’utilisation et l’accès a notre
                                    site web www.netikash.com,<br/>de l’utilisation de toutes vos informations
                                    d’identification personnelles à des fins de marketing. Nous vous fournirons, si vous
                                    le demandez explicitement, une possibilité de refuser l'utilisation de vos
                                    informations d’identification personnelles à des fins de marketing. </p> <h5>ARTICLE
                                    10 : SITES WEB DES TIERCES PARTIES</h5><p> Notre site web www.netikash.com contient
                                    des hyperliens vers des sites web de tiers et des détails les concernant. Nous
                                    n'avons pas contrôle et ne sommes pas responsables des politiques et des pratiques
                                    de confidentialité des tiers. </p> <h5>ARTICLE 11 : MISE A JOUR DES
                                    INFORMATIONS</h5><p> Vous avez tous les droits possibles sur vos informations
                                    d’identification personnelles que nous détenons sur vous sur notre site web
                                    www.netikash.com. Vous pouvez à tout moment accéder à ces informations et confirmer
                                    qu'elles sont correctes et à jour. Vous pouvez les modifier à partir de votre espace
                                    de gestion de vos informations d’identification personnelles, sur notre site web.
                                    Vous pouvez aussi choisir, si vous le souhaitez, de recevoir des informations
                                    supplémentaires de notre part ou de la part de nos partenaires, en vous connectant
                                    sur notre site web et en le spécifiant dans la page gérant les données que vous
                                    voulez recevoir, dans votre compte d'utilisateur. </p> <h5>ARTICLE 12 : COOKIES</h5>
                                    <p> Notre site web www.netikash.com utilise des cookies. Un cookie est un fichier
                                        contenant un identifiant (une chaîne de lettres et chiffres) qui est envoyé par
                                        un serveur web à unnavigateur web et est stocké par le navigateur sur un
                                        ordinateur. L'identifiant est ensuite renvoyé au serveur chaque fois que le
                                        navigateur demande une page du serveur. Les cookies peuvent être des cookies
                                        “persistants” ou "de session". Un cookie persistant sera stocké par un
                                        navigateur web et restera valables jusqu'à la date d'expiration fixée, sauf
                                        suppression expresse par l'utilisateur avant l'expiration.</p><p> Un cookie de
                                    session expirera à la fin de la session de l'utilisateur, lorsque le navigateur web
                                    sera fermé. Les cookies ne contiennent généralement aucune information qui identifie
                                    personnellement un utilisateur, mais les informations personnelles que nous stockons
                                    à votre sujet peuvent être liées aux informations stockées dans les cookies et
                                    obtenues à partir de ces dernières. Sur notre site web, nous utilisons les deux
                                    types de cookies à savoir les cookies persistants et les cookies de session. </p>
                                    <p> La plupart des navigateurs vous autorisent à refuser d’accepter les cookies.
                                        Vous pouvez toujours consulter la documentation spécifique sur votre navigateur
                                        pour en savoir plus sur la gestion des cookies. </p> <p> Le blocage de tous les
                                    cookies aura un impact négatif sur la facilité d'utilisation de nombreux sites
                                    Internet. Si vous bloquez les cookies, vous ne pourrez pas utiliser toutes les
                                    fonctionnalités de notre site web www.netikash.com. </p> <p> La suppression des
                                    cookies aura un impact négatif sur la facilité d'utilisation de nombreux sites
                                    Internet. </p> <h5>13 : CAS DES ENFANTS DE MOINS DE 13 ANS</h5><p> Netikash croit
                                    fermement en la protection de la vie privée des enfants. Conformément à cette
                                    conviction, nous ne collectons ni ne conservons sciemment aucune information
                                    d’identification personnelle sur notre site des personnes dont l’âge est inférieur à
                                    13 ans révolus. Aucune partie de notre site n'est destinée à des personnes de moins
                                    de 13 ans. Si vous avez moins de 13 ans, veuillez ne pas utiliser ou accéder à ce
                                    site à quelque moment que ce soit, de quelque manière que ce soit. Nous prendrons
                                    les mesures appropriées pour supprimer toute information d’identification
                                    personnelle des personnes de moins de 13 ans ayant été collectée sur notre site sans
                                    aucune autorisation parentale vérifiée après avoir appris l'existence de telles
                                    informations. </p> <h5>14 : QUESTIONS, SUGGESTIONS, RECLAMATIONS</h5><p> Si vous
                                    avez des questions, des suggestions, des problèmes non résolus ou des plaintes
                                    concernant la confidentialité et l’utilisation de vos données
                                    d’identification personnelles, vous pouvez nous contacter par courrier électronique
                                    à l’adresse <a
                                        href="https://www.netikash.com">www.netikash.com</a>.
                                </p><p> Nous répondrons le plus rapidement possible à tous les courriels, dans la mesure
                                    du possible, et dans les délais prescrits par la loi en vigueur dans le pays où nous
                                    opérons. Cependant, gardez à l'esprit qu'il restera toujours des informations
                                    résiduelles dans nos bases de données, tels que journaux d'accès à notre site web
                                    www.netikash.com et autres enregistrements, susceptibles de contenir ou non
                                    certaines de vos informations d'identification personnelle. Veuillez également noter
                                    que certaines informations d’identification personnelles peuvent être exemptées de
                                    telles demandes dans certaines circonstances, notamment si nous devons continuer à
                                    traiter vos informations d’identification personnelles pour respecter une obligation
                                    légale.

                                </p><p> Lorsque vous nous envoyez un e-mail avec une demande, nous pouvons vous demander
                                    de nous fournir les informations nécessaires pour confirmer votre identité. </p>
                                </ul>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default PrivacyPolicy;