import React from "react";
import "./HeadCard.css";
import netpay from '../../assets/images/netpay.jpg';
import netpos from '../../assets/images/posmobile.png';
import netsub from "../../assets/images/netsub.jpg";
import netw from '../../assets/images/netw.jpg';
import 'animate.css';

const HeadCard = () => {
    return (
        <div className="container HeadCard">
            <div className="headIN">
                <div className="col-md-4">
                    <img src={netw} alt="Netikash Pay" />
                    <h3 className="h3">Pay</h3>
                </div>
                <div className="vertical-divider"></div>
                <div className="col-md-4">
                    <img src={netsub} alt="Netikash Subscribe" style={{width:55}}/>
                    <h3 className="h3">Subscribe</h3>
                </div>
                <div className="vertical-divider"></div>
                <div className="col-md-4">
                    <img src={netpay} alt="Netikash Payme" />
                    <h3 className="h3">Payme</h3>
                </div>
                <div className="vertical-divider"></div>
                <div className="col-md-4">
                    <img src={netpos} alt="Netikash Pos" style={{width:55}}/>
                    <h3 className="h3">Pos</h3>
                </div>
            </div>
        </div>
    );
};

export default HeadCard;
