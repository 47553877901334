import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Stars from "../../assets/icons/stars.png";
import "./CarbonCarousel.css"
import {useTranslation} from "react-i18next";

const CarbonCarousel = () => {
  const { t } = useTranslation();
  return (
    <Container  className="p-0 d-flex align-items-center justify-content-center carbon-carousel">
      <Row className="d-flex flex-column">
        <Col xs={12} md={6} lg={4} className="justify-content-center etoile position-relative w-100 mt-4">
          <div className="d-flex justify-content-center">
            <img src={Stars} alt="Stars" className="mx-auto" style={{ width: 170 }} />
          </div>
        </Col>
        <Col xs={12} md={6} lg={4} className="fw-bold w-100">
          <p className="text-center" style={{ fontSize: 38, color: "#430C05", fontFamily:'Roobert, sans-serif' }}>
            {t('end.text')}
          </p>
        </Col>
        <Col xs={12} md={6} lg={4} className="d-flex justify-content-center w-100">
          <div className="m-1 button-87 position-relative mb-4">
            <a href='https://accounts.netikash.com' style={{ fontSize: 16, lineHeight: 2, padding: 2, textDecoration:"none", color:'white', fontFamily:'Roobert, sans-serif' }}>
              {t('foot.fin')}
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CarbonCarousel;