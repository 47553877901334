import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import LogoNet from "../../assets/icons/logoNet.png";
import "./Nav.css";
import {Link} from 'react-router-dom'
import LanguageSwitcher from "../LanguageSwitcher";
import {changeLanguage} from "i18next";
import {useTranslation} from "react-i18next";

const NavBar = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(null);
  return (
    <div className="container-fluid navi p-0">
      <Navbar expand="lg" className="p-3 nari" style={{ borderRadius: 10 }}>
        <Navbar.Brand href="#home" className="me-4">
          <img
            src={LogoNet}
            className="img-responsive"
            style={{ width: 180 }}
            alt="Netikash"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav w-100">
          <Nav className="me-auto w-75 justify-content-center">
            <Nav.Link
              href="https://dashboard.netikash.com"
              target="_blank"
              className="m-2"
              style={{ color: "#2c1762", fontSize: 20, fontFamily:'Roobert, sans-serif' }}
            >
              Pay
            </Nav.Link>
            <Nav.Link href="https://payme.netikash.com" target="_blank" className="m-2" style={{ color: "#2c1762", fontSize: 20, fontFamily:'Roobert, sans-serif' }}>
              Payme
            </Nav.Link>
            <Nav.Link href="https://subscribe.netikash.com" target="_blank" className="m-2" style={{ color: "#2c1762", fontSize: 20, fontFamily:'Roobert, sans-serif' }}>
              Subscribe
            </Nav.Link>
            <Nav.Link as={Link} to={"/#pos-section"} className="m-2" style={{ color: "#2c1762", fontSize: 20, fontFamily:'Roobert, sans-serif' }}>
              POS
            </Nav.Link>
          </Nav>
          <Nav className=" justify-content-center 25">
            <LanguageSwitcher changeLanguage={changeLanguage} />
            <Button variant="outline-light" href="https://accounts.netikash.com" className="position-relative rounded-pill fw-bold ms-5" style={{ color: 'white', backgroundColor: 'black', borderColor: 'black', fontFamily:'Roobert, sans-serif', fontSize:14, alignContent:'center', justifyContent:'center' }}>
              {t('navBar.connexion')}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
