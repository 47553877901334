import React, { useState } from 'react';
import { Container, Row, Col, Image, Dropdown } from 'react-bootstrap';
import Facebook from "../../assets/icons/facebook.png"
import Instagram from "../../assets/icons/instagram.png"
import Linkedin from "../../assets/icons/linkedin.png"
import Youtube from "../../assets/icons/youtube.png"
import Logos from "../../assets/icons/logos.png"
import Rdc from "../../assets/icons/rdc.png"
import Play from "../../assets/images/play.png"
import Store from "../../assets/images/store.png"
import LogoNet from "../../assets/icons/logoNet.png"
import "./Sitefooter.css"
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const SiteFooter = () => {
  const [selectedCountry, setSelectedCountry] = useState("Rdc");
  const [showCountry, setShowCountry] = useState(false);
  const { t } = useTranslation();
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowCountry(false);
  };

  return (
      <Container fluid className='foot'>
        <Row className="footer-content mt-5">
          <Col md={3} sm={6} className='mt-5'>
            <img
                src={LogoNet}
                className='w-50 mb-2'
                alt='netikash logo'
            />
          </Col>
          <Col md={3} sm={6} className='mt-5 d-flex flex-column align-items-center' style={{ textAlign: 'start' }}>
            <p className='fothead me-5'>Legal</p>
            <ul style={{ listStyleType: 'none' }}>
              <li style={{ color: 'white', fontSize: 15 }}>
                <Link to='/PrivacyPolicy' style={{textDecoration:'none', color:'white', transition: 'opacity .3s'}}> Privacy policy</Link>
              </li>
              <li style={{ color: 'white', fontSize: 15 }}>
               <Link to='/TermsAndConditions' style={{textDecoration:'none', color:'white'}}> Terms & Conditions</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={6} className='mt-5 d-flex flex-column align-items-center' style={{ textAlign: 'start' }}>
            <p className='fothead me-5'>{t('footer.Footer')}</p>
            <div className='d-flex flex-row'>
              <a href='' style={{textDecoration: 'none'}}>
                <Image src={Store} fluid className='me-2' style={{width: 100, height: 30, marginBottom: 5}}/>
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.netikash.netikashwalletapp' target='_blank' style={{textDecoration: 'none'}}>
                <Image src={Play} fluid className='me-3' style={{width: 100, height: 30}}/>
              </a>
            </div>
          </Col>
          <Col md={3} sm={6} className='mt-5 d-flex flex-row align-items-center'>
            <div className='d-flex flex-row mb-2'>
              <a href='https://www.facebook.com/netikash' target='_blank' className='me-2'>
                <Image src={Facebook} fluid style={{ width: 20, height: 20 }} />
              </a>
              <a href='https://twitter.com/netikash' target='_blank' className='me-2'>
                <Image src={Logos} fluid style={{ width: 20, height: 20 }} />
              </a>
              <a href='https://www.instagram.com/netikash.rdc/' target='_blank' className='me-2'>
                <Image src={Instagram} fluid style={{ width: 20, height: 20 }} />
              </a>
              <a href='https://www.youtube.com/@netikash3587' target='_blank' className='me-2'>
                <Image src={Youtube} fluid style={{ width: 20, height: 20 }} />
              </a>
              <a href='https://www.linkedin.com/company/netikash/' target='_blank' className='me-2'>
                <Image src={Linkedin} fluid style={{ width: 20, height: 20 }} />
              </a>
              </div>
          </Col>
        </Row>
      </Container>
  );
};

export default SiteFooter;