import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from './components/header/Navbar';
import Routing from "./routes/routing";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routing />
            </div>
        </Router>
    );
}

export default App;
