import React from 'react';
import './Head.css';
import { Carousel } from 'react-bootstrap';
import { Button as BootstrapButton } from 'react-bootstrap';
import Pmy from "../../assets/images/pmy.png"
import Font5 from '../../assets/images/font5.png'
import pos from '../../assets/images/Posmobcart.png'
import Sbb from '../../assets/images/sbb.png'
import { Fade } from "react-awesome-reveal";
import 'animate.css';
import {useTranslation} from "react-i18next";
const Button = (props) => {
  return (
    <BootstrapButton
      {...props}
      className={`button-52 ${props.className}`}
    />
  );
};

const Head = () =>{
    const { t } = useTranslation();
  return (
    <div className='container-fluid header'>
      <div className='container'>
        <div className='d-flex flex-column flex-md-row'>
          <div className='d-flex flex-column col-md-6 justify-content-start'>
            <h2 className='mt-5 mb-5 titres'>{t('head.Titre')}</h2>
            <h6 style={{color:'#000', lineHeight:1.2, fontSize:20, fontFamily:'Roobert, sans-serif'}} className='mt-1'>
                {t('head.Description')}
            </h6>
            <div className='button d-flex flex-row justify-content-around p-3 fw-bold btn-block mx-auto mb-3 mb-md-0 mt-5'
              style={{
                fontSize: '1rem',
                maxWidth: '100%',
                width: '250px',
                margin: '0 auto',
              }}>
              <a href='https://accounts.netikash.com' target='_blank' style={{textDecoration:'none', color:'white', fontFamily:'Roobert, sans-serif'}}>{t('head.push')}</a>
            </div>
          </div>
          <div className='col-md-6 d-flex align-items-center'>
          <Carousel interval={2000} controls={false} indicators={false} className='w-100'>
              <Carousel.Item>
              <Fade>
                <img
                  className="d-block w-75"
                  src={Pmy}
                  alt="First slide"
                />
                </Fade>
              </Carousel.Item>
              <Carousel.Item>
                <Fade>
                <img
                  className="d-block w-75"
                  src={Font5}
                  alt="Second slide"
                />
                </Fade>
              </Carousel.Item>
              <Carousel.Item>
                  <Fade>
                      <img
                          className="d-block w-75"
                          src={Sbb}
                          alt="Second slide"
                      />
                  </Fade>
              </Carousel.Item>
              <Carousel.Item>
                  <Fade>
                      <img
                          className="d-block w-100"
                          style={{marginBottom:'25%'}}
                          src={pos}
                          alt="Second slide"
                      />
                  </Fade>
              </Carousel.Item>
          </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Head;